import {
  Button,
  Card,
  Enter,
  FormGroup,
  Grid,
  HelpText,
  Input,
  Label,
  Spacing,
  Wrap,
} from '@etchteam/mobius';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';

import FormErrors from 'components/content/FormErrors/FormErrors';
import InputErrors from 'components/content/InputErrors/InputErrors';
import PageTitle from 'components/content/PageTitle/PageTitle';
import { useUser } from 'lib/hooks';
import regexes from 'lib/regexes';

const LOGIN = `
mutation login($email: EmailAddress!, $password: String!) {
  login(email: $email, password: $password) {
    user {
      id
    }
  }
}
`;

const Login = () => {
  const { t } = useTranslation();
  const [result, handleLogin] = useMutation(LOGIN);
  const [emailValue, setEmailValue] = useState('');
  const router = useRouter();
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onBlur' });
  const [user, refetchUser] = useUser();

  const onSubmit = (data) => {
    handleLogin(data).then(() => {
      refetchUser({ requestPolicy: 'network-only' });
    });
  };

  useEffect(() => {
    if (user?.id) {
      const next = router.query.next as string;

      if (next === undefined || next.includes('404')) {
        router.push('/dashboard');
      } else {
        router.push(next);
      }
    }
  }, [router, user]);

  return (
    <Wrap>
      <PageTitle title="Log in" />
      <Spacing top="fluid" bottom="fluid">
        <Grid justify="center">
          <Grid.Item xs={12} sm={10} md={8} lg={6} xl={4}>
            <Enter type="fade-in-up">
              <Card border shadow>
                <Card.Header>
                  <h2>Log in to your account</h2>
                </Card.Header>
                <Card.Body>
                  <form
                    method="POST"
                    action="/dashboard"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Grid wrap>
                      {!!result?.error && (
                        <Grid.Item xs={12}>
                          <FormErrors error={result.error} />
                        </Grid.Item>
                      )}
                      <Grid.Item xs={12}>
                        <FormGroup>
                          <Label htmlFor="email">Email</Label>
                          <Input
                            type="email"
                            placeholder="someone@council.gov.uk"
                            valid={!errors.email}
                            touched={touchedFields.email}
                            onInput={(e) => setEmailValue(e.target.value)}
                            maxLength="320"
                            {...register('email', {
                              required: true,
                              pattern: {
                                value: regexes.email,
                                message: t('common.errors.email'),
                              },
                              maxLength: 320,
                            })}
                          />
                          <InputErrors error={errors.email} />
                        </FormGroup>
                      </Grid.Item>
                      <Grid.Item xs={12}>
                        <FormGroup>
                          <Label htmlFor="password">Password</Label>
                          <Input
                            type="password"
                            valid={!errors.password}
                            touched={touchedFields.password}
                            {...register('password', { required: true })}
                          />
                          <InputErrors error={errors.password} />
                          <HelpText>
                            <Link
                              href={`/auth/forgot-password?email=${emailValue}`}
                            >
                              Forgotten password?
                            </Link>
                          </HelpText>
                        </FormGroup>
                      </Grid.Item>
                    </Grid>

                    <Spacing top="lg">
                      <Button submit type="primary" block>
                        Continue
                      </Button>
                    </Spacing>
                  </form>
                </Card.Body>
              </Card>
            </Enter>
          </Grid.Item>
        </Grid>
      </Spacing>
    </Wrap>
  );
};

export default Login;
